<template>
  <!-- Navbar (sit on top) -->
  <div class="w3-top">
    <div class="w3-bar w3-white w3-wide w3-padding w3-card">
      <a href="#kidskoor" class="w3-bar-item w3-button"
        ><b>Licht</b> Kidskoor</a
      >
      <!-- Float links to the right. Hide them on small screens -->
      <div class="w3-display-middle w3-hide-medium w3-hide-small">
        <a href="#kidskoor" class="w3-bar-item w3-button">Kidskoor</a>
        <a href="#informatie" class="w3-bar-item w3-button">Informatie</a>
        <a href="#contact" class="w3-bar-item w3-button">Contact</a>
      </div>
    </div>
  </div>

  <!-- Header -->
  <header
    class="w3-display-container w3-content w3-wide"
    style="max-width: 1500px"
    id="kidskoor"
  >
    <div class="w3-display-left w3-margin-top w3-center">
      <h3 class="w3-hide-medium w3-hide-small w3-xxlarge w3-text-dark-grey">
        <span class="w3-hide-medium w3-hide-small w3-text-dark-grey"
          >Samen zingen</span
        >
      </h3>
    </div>
    <img
      class="w3-image"
      id="responsive-bulb"
      src="../assets/logo.png"
      alt="Licht"
      width="700"
      height="auto"
    />
    <div class="w3-display-right w3-margin-top w3-center">
      <h3 class="w3-hide-medium w3-hide-small w3-xxlarge w3-text-dark-grey">
        <span class="w3-hide-medium w3-hide-small w3-text-dark-grey"
          >Enthousiast voor God</span
        >
      </h3>
    </div>
  </header>

  <!-- Page content -->
  <div class="w3-content w3-padding" style="max-width: 1564px">
    <!-- Project Section -->
    <div class="w3-container w3-padding-32" id="informatie">
      <h3 class="w3-border-bottom w3-border-light-grey w3-padding-16">
        Informatie
      </h3>
    </div>

    <p>
      Wij zijn het Licht Kids-koor, een christelijk kinderkoor in Houten.<br />
      We zijn georganiseerd vanuit de kerk De Lichtboog, maar alle kinderen uit
      de groep 3 t/m 8 zijn welkom. <br />
      We vinden het leuk als je mee komt zingen. Je kunt het een paar keer
      proberen. We oefenen op donderdagmiddag.
    </p>
    <p>
      Groep 6-7-8 repeteert van 15.30u - 16.15u
      <br />
      Groep 3-4-5 repeteert van 15.45u - 16.30u
    </p>
    <p>
      Als je het heel leuk vindt, kan je lid worden voor maar €100
      per jaar. <br />
      Dan kan je met ons oefenen en optreden. En leer je veel van onze dirigent
      Pearl Jozefzoon. Wil je nog meer weten dan kan je contact opnemen:
    </p>
    <svg width="auto" height="100mm" viewBox="0 0 100 100">
      <g
        inkscape:label="Layer 1"
        inkscape:groupmode="layer"
        id="layer1"
        transform="translate(0,-197)"
      >
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 64.126503,220.93435 c 0,0 -3.718021,-6.90489 -10.88849,-7.43604 -5.577032,-0.53114 -13.278647,2.65572 -15.137659,5.8426 -1.859009,3.18688 -3.186876,8.23275 0,13.5442 3.186876,5.31149 3.718024,5.84262 1.327867,8.49834 -2.390157,2.65573 -6.354879,5.15315 -5.764822,9.07507 0.64803,4.30722 3.012432,3.29965 1.950141,8.07994 -1.06229,4.78033 -3.621364,4.88756 -3.621364,7.27772 0,2.39016 3.186878,5.31146 2.124584,7.96718 -1.06229,2.65573 -4.780311,7.17047 -4.780311,7.17047"
          id="path846"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="ccsssssssc"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 33.851188,246.69494 c -4.116381,3.18685 -7.967189,4.51473 -11.950783,3.31963 -3.983596,-1.19506 -8.763905,-0.26556 -8.763905,-0.26556"
          id="path848"
          inkscape:connector-curvature="0"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 61.869134,236.60315 c -1.859009,3.05408 -3.054087,6.9049 -2.390157,8.89669 0.663933,1.99179 0.39836,3.71801 0,5.84261 -0.398358,2.12457 -1.460651,3.31967 -0.796718,5.17868 0.663933,1.859 2.124584,2.52294 0.796718,6.50654 -1.327864,3.98358 -4.116379,5.84259 -4.116379,7.70162 0,1.859 1.991797,3.45244 0.929506,6.50652 -1.062293,3.0541 -4.116382,4.11639 -3.452451,5.97539"
          id="path850"
          inkscape:connector-curvature="0"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 61.072416,246.42934 c 2.522942,3.0541 5.31146,7.96721 9.295051,8.63112 3.983596,0.66395 8.896694,-2.12457 10.888491,-2.92129 1.991799,-0.79672 7.170469,-4.24917 7.170469,-4.24917 0,0 -0.265573,-6.37374 -2.9213,-8.7639 -2.655728,-2.39016 -3.983594,-6.63933 -3.983594,-6.63933"
          id="path852"
          inkscape:connector-curvature="0"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 72.757629,266.6129 c 1.327859,-2.25738 17.262239,-10.75572 18.324533,-10.75572 1.062288,0 4.249167,-1.859 4.249167,-1.859 0,0 0.796711,-8.89669 -0.265571,-12.08357 -1.062298,-3.18688 -4.381959,-5.97539 -4.249169,-7.43606 0.132782,-1.46062 -1.460652,-5.31145 -1.460652,-5.31145 0,0 -5.709817,1.1951 -6.772111,2.25738 -1.062293,1.06229 -1.062293,1.06229 -1.062293,1.06229"
          id="path854"
          inkscape:connector-curvature="0"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 23.493842,259.84079 c -3.71802,-0.13276 -11.817999,-1.19509 -12.747503,-1.32788 -0.9295039,-0.13276 -3.5852315,-3.45245 -3.9835947,-6.24095 -0.3983564,-2.78852 6.7721127,-14.60652 7.7016167,-15.9344 0.929504,-1.32785 7.436044,-9.02947 7.436044,-9.02947 0,0 2.522943,1.72623 3.054089,2.65573 0.531146,0.92951 1.593438,3.18688 1.593438,3.18688 0,0 -1.726225,4.51474 -2.788517,5.57703 -1.062291,1.06228 -4.647529,5.17867 -5.045886,6.77211 -0.398357,1.59344 -0.929504,3.31967 -0.929504,3.31967"
          id="path856"
          inkscape:connector-curvature="0"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 64.126503,220.93435 c -1.06229,2.52295 -0.66393,3.58524 0.132784,6.24096 1.062291,2.9213 0.663933,4.51474 0.132783,7.30324 -0.265573,2.25739 -2.390155,5.9754 -0.663932,7.43606 2.124586,1.19507 1.460652,3.45245 0.929507,4.38195 -0.531148,0.92951 1.062291,3.31967 1.062291,3.31967"
          id="path858"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="ccccsc"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 81.654323,230.49498 c -5.178673,-2.1246 -7.037687,-5.31146 -6.108183,-5.0459 0.92951,0.26557 3.518842,1.46067 3.518842,0.79673 0,-0.66394 -0.995897,-2.32376 -2.058191,-2.9877 -1.062289,-0.66393 -3.585231,-4.31554 -3.585231,-4.31554 0,0 -0.602606,-2.67619 0.531145,-3.0541 1.195079,-0.39835 4.581131,0.79672 5.975393,2.12457 0.543936,0.51804 1.593435,2.52294 2.390155,3.45245 0.796719,0.92952 2.058191,4.38195 2.058191,4.38195 l 2.854906,2.52294"
          id="path860"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="cssccssccc"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.56173021px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 28.00373,229.50821 c 4.876501,-1.53144 6.815027,-4.28682 5.948734,-4.11727 -0.866293,0.1695 -3.314841,1.0561 -3.263356,0.45203 0.05148,-0.60409 1.086305,-2.03703 2.104302,-2.55872 1.017999,-0.5217 3.596633,-3.64843 3.596633,-3.64843 0,0 0.7558,-2.38816 -0.246424,-2.81992 -1.056437,-0.45511 -4.229866,0.36963 -5.601385,1.46966 -0.535068,0.42915 -1.645413,2.1719 -2.442378,2.95582 -0.796962,0.7839 -2.212418,3.82726 -2.212418,3.82726 l -2.793147,2.07408"
          id="path860-9"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="cssccssccc"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 23.493842,259.84079 c -0.06639,1.92541 1.327865,5.31146 0.464754,8.7639 -0.863113,3.45245 -1.792618,6.83852 -1.925404,8.23277"
          id="path877"
          inkscape:connector-curvature="0"
        />
        <path
          style="
            fill: none;
            stroke: #320080;
            stroke-width: 0.61516613px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 0.99607843;
          "
          d="m 71.894513,267.40962 c -1.128681,2.65573 -3.784416,7.36965 -3.186874,10.15816 0.597535,2.78851 0.331969,5.51065 0.331969,5.51065"
          id="path879"
          inkscape:connector-curvature="0"
        />
      </g>
    </svg>

    <svg
      class="w3-hide-medium w3-hide-small"
      width="auto"
      height="100mm"
      viewBox="0 0 100 100"
      version="1.1"
      id="svg1508"
    >
      <g
        inkscape:label="Layer 1"
        inkscape:groupmode="layer"
        id="layer1"
        transform="translate(0,-197)"
      >
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.64199144px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 21.827017,268.72714 c 9.064407,1.6779 11.535585,-2.25725 8.419723,-4.52926 -1.384511,-1.00956 -4.363332,-9.87978 -1.150484,-14.12542 3.488344,-4.60974 13.354534,-1.5978 12.735206,4.39541 -0.619332,5.99321 -3.070023,8.86323 -4.689275,10.01781 -2.310518,1.64745 0.308943,4.34559 9.405944,4.24146"
          id="path5269-8"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csszsc"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.59834433px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 32.451446,258.27817 c -0.90857,0.5462 -0.387478,3.12116 0.895201,3.04313 1.282687,-0.0781 2.651008,-2.17211 1.710252,-2.9651 -0.779861,-0.55093 -1.696888,-0.62424 -2.605453,-0.0781 z"
          id="path5298"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="zscz"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.64199144px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 42.753658,253.06792 c 9.064404,1.6779 11.535582,-2.25725 8.419722,-4.52928 -1.384513,-1.00955 -4.36333,-9.87977 -1.150483,-14.1254 3.488343,-4.60974 13.354529,-1.5978 12.735203,4.3954 -0.619332,5.9932 -3.070021,8.86323 -4.689275,10.0178 -2.310516,1.64745 0.308945,4.34557 9.405943,4.24148"
          id="path5269-8-7"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csszsc"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.64698273px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 52.677987,243.17844 c -1.037123,0.55945 -0.442306,3.19688 1.021863,3.11696 1.464172,-0.0799 3.026089,-2.22482 1.952228,-3.03707 -0.8902,-0.56429 -1.936974,-0.63935 -2.974091,-0.0799 z"
          id="path5298-8"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="zscz"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.59662926px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 69.90912,280.82378 c 8.246539,1.59289 10.494742,-2.14288 7.660022,-4.29979 -1.259586,-0.95839 -3.96963,-9.37918 -1.046678,-13.40971 3.173598,-4.37618 12.149578,-1.51684 11.586129,4.17269 -0.563454,5.68957 -2.793021,8.41416 -4.266172,9.51025 -2.102039,1.56398 0.281071,4.12541 8.557262,4.02656"
          id="path5269-8-5"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csszsc"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.51319212px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 79.54199,271.35326 c -0.835302,0.43705 -0.356232,2.49743 0.823006,2.43499 1.179247,-0.0624 2.437224,-1.73804 1.572326,-2.37255 -0.716964,-0.44082 -1.560041,-0.49948 -2.395332,-0.0624 z"
          id="path5298-4"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="zscz"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.56320417px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 64.697248,259.8463 c 7.838842,1.49325 9.975901,-2.00879 7.281324,-4.03075 -1.197314,-0.89846 -3.773384,-8.7924 -0.994931,-12.57078 3.016696,-4.10238 11.548914,-1.42195 11.013328,3.91163 -0.5356,5.33359 -2.654939,7.88776 -4.055259,8.91526 -1.998123,1.46614 0.267173,3.86732 8.134203,3.77464"
          id="path5269-8-9"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csszsc"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.61609387px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 73.298788,250.21876 c -0.966886,0.54416 -0.412352,3.1095 0.95266,3.03177 1.365012,-0.0777 2.821156,-2.16402 1.820015,-2.95404 -0.829912,-0.54888 -1.805794,-0.6219 -2.772675,-0.0777 z"
          id="path5298-46"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="zscz"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.6029619px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 46.46982,276.93264 c 8.657352,1.54967 11.017557,-2.08476 8.041622,-4.18315 -1.32234,-0.93242 -4.16739,-9.1248 -1.098824,-13.04598 3.331701,-4.25745 12.754834,-1.47571 12.163316,4.05951 -0.59152,5.53521 -2.932156,8.18592 -4.478701,9.25228 -2.206758,1.52153 0.295077,4.0135 8.98356,3.91734"
          id="path5269-8-99"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csszsc"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.5804202px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 55.847503,267.82276 c -0.817804,0.571 -0.348775,3.26293 0.805768,3.18135 1.154543,-0.0815 2.386164,-2.27079 1.539392,-3.09981 -0.701954,-0.57593 -1.527363,-0.65256 -2.34516,-0.0815 z"
          id="path5298-1"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="zscz"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.53334689px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 71.761435,239.25593 c 7.773699,1.35033 9.893001,-1.81657 7.220817,-3.64502 -1.187375,-0.81249 -3.742029,-7.95097 -0.986666,-11.36775 2.991626,-3.70976 11.452949,-1.28586 10.921806,3.53731 -0.531138,4.82315 -2.632869,7.13286 -4.021554,8.06204 -1.981523,1.32581 0.264954,3.4972 8.066606,3.41342"
          id="path5269-8-0"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csszsc"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.46706107px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 80.749649,231.22757 c -0.816165,0.37048 -0.348072,2.1171 0.80415,2.06418 1.152233,-0.053 2.38138,-1.47338 1.536307,-2.01128 -0.700538,-0.37368 -1.524303,-0.42341 -2.340457,-0.0529 z"
          id="path5298-7"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="zscz"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.58315289px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 5.1724069,252.01979 c 8.2480861,1.52147 10.4967161,-2.04677 7.6614641,-4.10694 -1.25983,-0.91544 -3.9703851,-8.95858 -1.046878,-12.80838 3.174194,-4.17993 12.151863,-1.4488 11.588312,3.98559 -0.563558,5.43439 -2.793547,8.03681 -4.266972,9.08375 -2.102441,1.49383 0.281121,3.9404 8.558873,3.84598"
          id="path5269-8-6"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csszsc"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.55352092px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 14.223009,242.97399 c -1.017362,0.41744 -0.433877,2.38542 1.0024,2.32579 1.43628,-0.0596 2.968441,-1.6601 1.915036,-2.26616 -0.873238,-0.42107 -1.900075,-0.47707 -2.917436,-0.0596 z"
          id="path5298-44"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="zscz"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.56227112px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 25.632172,240.88869 c 8.044723,1.45021 10.237911,-1.95092 7.472562,-3.91461 -1.228764,-0.87256 -3.872485,-8.53901 -1.021061,-12.2085 3.095927,-3.98416 11.852235,-1.38097 11.302584,3.79893 -0.549663,5.17987 -2.72467,7.66044 -4.16177,8.6583 -2.050596,1.4239 0.274193,3.75589 8.347841,3.66588"
          id="path5269-8-8"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csszsc"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.50208658px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 34.305306,231.78552 c -0.717511,0.487 -0.306,2.78292 0.706951,2.71334 1.01296,-0.0695 2.093538,-1.93673 1.350613,-2.64378 -0.615866,-0.49122 -1.34006,-0.55658 -2.057564,-0.0695 z"
          id="path5298-88"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="zscz"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.5348767px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 55.765486,229.74776 c 7.499149,1.40781 9.543593,-1.89389 6.96579,-3.80017 -1.145431,-0.84704 -3.609863,-8.28939 -0.951817,-11.85162 2.885967,-3.86769 11.048451,-1.34059 10.53607,3.68788 -0.512389,5.02845 -2.539891,7.43649 -3.879529,8.40521 -1.911532,1.38226 0.2556,3.64605 7.781712,3.5587"
          id="path5269-8-66"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csszsc"
        />
        <path
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #0088aa;
            stroke-width: 0.52308881px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-opacity: 1;
          "
          d="m 63.910607,221.4698 c -0.743806,0.50991 -0.317214,2.91381 0.73287,2.84097 1.050085,-0.0728 2.170271,-2.02783 1.400111,-2.76812 -0.638436,-0.51434 -1.389174,-0.58275 -2.132981,-0.0728 z"
          id="path5298-2"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="zscz"
        />
      </g>
    </svg>

    <p>
      We leren veel van onze dirigent Pearl Jozefzoon. Zij is zangeres, schrijft
      zelf liedjes, ze is zangcoach en voor ons (en andere koren) dirigent.
    </p>

    <!-- Contact Section -->
    <div class="w3-container w3-padding-32" id="contact">
      <h3 class="w3-border-bottom w3-border-light-grey w3-padding-16">
        Contact
      </h3>
      <p>Als je nog vragen hebt horen we het graag. Stuur een mail naar ons bestuur: Anke Esmeijer en Manon Mol: <a href="mailto: kidskoor@delichtboog.nl?subject=Meer%20informatie">kidskoor@delichtboog.nl</a> </p>

    </div>

    <!-- Image of location/map -->
    <div class="w3-container">
      <div class="mapouter">
          <iframe
            width="600"
            height="500"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=lichtboog%20houten&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
      </div>
    </div>

    <!-- End page content -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#responsive-flamingo {
  width: 50%;
  height: auto;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
